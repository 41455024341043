import React from "react";
import logo from '../../logo192.png'; 
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
const AuthNavbar = () => {
    return (
        <React.Fragment>
            <Navbar bg="dark" expand="lg" className="navbar-dark">
                <Container className="justify-content-start">
                <Navbar.Brand variant="title">
                        <img src={logo} alt="bug" height={40} />
                </Navbar.Brand>
                    <Navbar.Brand className="ml-1">Quideos | Portal</Navbar.Brand>
                </Container>
            </Navbar>
        </React.Fragment>
    );
}
export default AuthNavbar;