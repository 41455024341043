import React from "react";
import { Container, Nav } from "react-bootstrap";
import Navbar from 'react-bootstrap/Navbar';
import { Link } from "react-router-dom";
const PortalFooter = () => {
    return (
        <React.Fragment>
            <footer className="bg-light border-top py-3 fixed-bottom">
                <Container>
                    <Nav className="ms-auto">
                        <Navbar.Brand  className="ms-auto">&copy; Quideos Dashboard - 2023: Quideos.com</Navbar.Brand> 
                        <Link className="ms-auto" as={"mailto:info@quideos.com"} to="mailto:info@quideos.com">Get in touch with us</Link> 
                    </Nav>
                </Container>
            </footer>
        </React.Fragment>
    );
}
export default PortalFooter;