import axios from "axios";
import React from "react";
import { Button, Col, Container, Form, FormGroup, FormLabel, Row } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";

const Login = () => {

    const loginAPI = 'https://dashboarduserconnectionapp.azurewebsites.net/login';
    // const loginAPI = 'http://localhost:8000/login';
    const navigate = useNavigate();
    const submitLoginForm = (event) => {
        event.preventDefault();
        const formElement = document.querySelector('#loginForm');
        const formData = new FormData(formElement);
        const formDataJSON = Object.fromEntries(formData);
        const btnPointer = document.querySelector('#login-btn');
        btnPointer.innerHTML = 'Please wait..';
        btnPointer.setAttribute('disabled', true);
        // console.log(formDataJSON);
        axios.post(loginAPI, formDataJSON).then((response) => {
            btnPointer.innerHTML = 'Login';
            btnPointer.innerHTML = 'Users';
            btnPointer.removeAttribute('disabled');
            const data = response.data;
            const token = data.token;
            // console.log(token);
            // const token = "test123"
            if (token == "wrongpassword") {
                alert('Wrong Password, please contact support team if you want to reset: info@quideos.com');
                return;
            } else if (token == "nouser"){
                alert('You are not registered, please contact our team to create you an account: info@quideos.com');
                return;
            } else if (token == "test123"){
                localStorage.clear();
                localStorage.setItem('user-token', token);
                setTimeout(() => {
                    navigate('/');
                }, 500);
            }

            }).catch((error) => {
                btnPointer.innerHTML = 'Login';
                btnPointer.removeAttribute('disabled');
                alert("Oops! Some error occured");

                // BELOW TO BE REMOVED
                // const token = "test123"
                // localStorage.clear();
                // localStorage.setItem('user-token', token);
                setTimeout(() => {
                    navigate('/');
                }, 500);
        });
    }

    return (
        <React.Fragment>
            <Container className="my-5">
                <h2 className="fw-normal mb-5">Login to Quideos Dashboard</h2>
                <Row>
                    <Col md={{span: 6}}>
                        <Form id="loginForm" onSubmit={submitLoginForm}>
                            <FormGroup className="mb-3">
                                <FormLabel htmlFor={'login-username'}>Username</FormLabel>
                                <input type={'text'} className="form-control" id={'login-username'} name="username" required />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormLabel htmlFor={'login-password'}>Password</FormLabel>
                                <input type={'password'} className="form-control" id={'login-password'} name="password" required />
                                <p></p>
                                <Link as={"mailto:info@quideos.com"} to="mailto:info@quideos.com">Forgot password? Please contact info@quideos.com</Link>
                            </FormGroup>
                            <Button type="submit" className="btn-success mt-2" id="login-btn">Login</Button>
                            <FormGroup className="mb-3">
                                <p className="fw-normal mb-5"></p>
                                <Button type="submit" className="btn-info mt-2" id="signup-btn">Sign-up: contact info@quideos.com</Button>
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );

}

export default Login;