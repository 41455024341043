import React, { useRef, useEffect } from 'react';
import './Home.css';
import styled from 'styled-components';

const IframeContainer = styled.div`
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 800px;
    height: calc(100% - 880px);
`;

const Iframe = styled.iframe`
  position: relative;
  width: 100vw;
  height: 80vh;
  overflow: hidden;
`;

const Home = () => {
    return (
        <React.Fragment>
            <IframeContainer>
                <Iframe src="https://quideosdashboardwebapp.azurewebsites.net" />
            </IframeContainer>
        </React.Fragment>
    )
}
export default Home;